import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { createEntity, getEntity, reset, updateEntity } from './user-verifications.reducer';

export const UserVerificationsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const userVerificationsEntity = useAppSelector(state => state.userVerifications.entity);
  const loading = useAppSelector(state => state.userVerifications.loading);
  const updating = useAppSelector(state => state.userVerifications.updating);
  const updateSuccess = useAppSelector(state => state.userVerifications.updateSuccess);

  const handleClose = () => {
    navigate(`/user-verifications${location.search}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...userVerificationsEntity,
      ...values,
      internalUser: users.find(it => it.id.toString() === values.internalUser?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...userVerificationsEntity,
          internalUser: userVerificationsEntity?.internalUser?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="nylabankapiApp.userVerifications.home.createOrEditLabel" data-cy="UserVerificationsCreateUpdateHeading">
            <Translate contentKey="nylabankapiApp.userVerifications.home.createOrEditLabel">Create or edit a UserVerifications</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="user-verifications-id"
                  label={translate('nylabankapiApp.userVerifications.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.email')}
                id="user-verifications-email"
                name="email"
                data-cy="email"
                type="text"
              />
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.emailVerified')}
                id="user-verifications-emailVerified"
                name="emailVerified"
                data-cy="emailVerified"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.idCard')}
                id="user-verifications-idCard"
                name="idCard"
                data-cy="idCard"
                type="text"
              />
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.idCardVerified')}
                id="user-verifications-idCardVerified"
                name="idCardVerified"
                data-cy="idCardVerified"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.countryCode')}
                id="user-verifications-countryCode"
                name="countryCode"
                data-cy="countryCode"
                type="text"
                validate={{
                  minLength: { value: 3, message: translate('entity.validation.minlength', { min: 3 }) },
                  maxLength: { value: 5, message: translate('entity.validation.maxlength', { max: 5 }) },
                }}
              />
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.mobile')}
                id="user-verifications-mobile"
                name="mobile"
                data-cy="mobile"
                type="text"
                validate={{
                  minLength: { value: 9, message: translate('entity.validation.minlength', { min: 9 }) },
                  maxLength: { value: 10, message: translate('entity.validation.maxlength', { max: 10 }) },
                }}
              />
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.mobileVerified')}
                id="user-verifications-mobileVerified"
                name="mobileVerified"
                data-cy="mobileVerified"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.token')}
                id="user-verifications-token"
                name="token"
                data-cy="token"
                type="text"
              />
              <ValidatedField
                label={translate('nylabankapiApp.userVerifications.tempPass')}
                id="user-verifications-tempPass"
                name="tempPass"
                data-cy="tempPass"
                type="text"
              />
              <ValidatedField
                id="user-verifications-internalUser"
                name="internalUser"
                data-cy="internalUser"
                label={translate('nylabankapiApp.userVerifications.internalUser')}
                type="select"
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/user-verifications" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserVerificationsUpdate;
